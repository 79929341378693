export const porcoes = [
  {
    id: '7BatataGrande',
    image:
      'https://firebasestorage.googleapis.com/v0/b/o-gosto-de-beirute.appspot.com/o/imagesGeneral%2FBatataFrita.jpg?alt=media&token=e456a551-ec2e-41dc-9141-93f7f9600f3f',
    name: 'Batata Frita Grande',
    ingredients: 'Batata Fina Frita, Com ou Sem Tempero de Casa',
    type: 'Porcao',
    prepTime: '6-10',
    price: '8.00',
    spicy: false,
    vegan: false,
  },
  {
    id: '8BatataPequena',
    image:
      'https://firebasestorage.googleapis.com/v0/b/o-gosto-de-beirute.appspot.com/o/imagesGeneral%2FBatataFrita.jpg?alt=media&token=e456a551-ec2e-41dc-9141-93f7f9600f3f',
    name: 'Batata Frita Pequena',
    ingredients: 'Batata Fina Frita, Com ou Sem Tempero de Casa',
    type: 'Porcao',
    prepTime: '6-10',
    price: '5.00',
    spicy: false,
    vegan: false,
  },
  {
    id: '9QuibeCarne',
    image:
      'https://firebasestorage.googleapis.com/v0/b/o-gosto-de-beirute.appspot.com/o/imagesGeneral%2FQuibedeCarne.jpg?alt=media&token=0004b6cf-9b60-4c91-b82d-99b387ea6b02',
    name: 'Quibe de Carne',
    ingredients: 'Quibe de Carne, no jeito Libanes original, Frita',
    type: 'Salgado',
    prepTime: '6-10',
    price: '3.50',
    spicy: false,
    vegan: false,
  },
  {
    id: '10PastelQuiejo',
    image:
      'https://firebasestorage.googleapis.com/v0/b/o-gosto-de-beirute.appspot.com/o/imagesGeneral%2FPastelzinhoDeQueijo.jpg?alt=media&token=6a9c845a-3128-4601-9113-0f8d179f43b0',
    name: 'Pastelzinho de Quiejo',
    ingredients: 'Pastelzinho recheado com Queijo, no jeito Libanes, Frita',
    type: 'Salgado',
    prepTime: '6-10',
    price: '3.50',
    spicy: false,
    vegan: false,
  },
  // {
  //   id: '11EsfihaCarne',
  //   image: '',
  //   name: 'Esfiha de Carne',
  //   ingredients: 'Esfiha de Carne verdadeira Libanesa, Assada',
  //   type: 'Salgado',
  //   prepTime: '6-10',
  //   price: '3.5,
  //   spicy: false,
  //   vegan: false,
  // },
  {
    id: '12MolhoEspecial',
    image:
      'https://firebasestorage.googleapis.com/v0/b/o-gosto-de-beirute.appspot.com/o/molhoEspecial.jpg?alt=media&token=f4870202-4f7a-49cc-b09c-cfbe9ae7049f',
    name: 'Molho Especial',
    ingredients: 'Nosso molho de casa, com os temperos de casa, Caseiro',
    type: 'Molho',
    prepTime: '2',
    price: '1.50',
    spicy: false,
    vegan: false,
  },
  {
    id: '13CremeDeAlho',
    image:
      'https://firebasestorage.googleapis.com/v0/b/o-gosto-de-beirute.appspot.com/o/molhoAlho.jpg?alt=media&token=54b878f9-31df-4d6c-8d0b-a272635d1ca9',
    name: 'Creme de Alho',
    ingredients:
      'Nosso creme de alho de casa, com os temperos de casa, Caseiro',
    type: 'Molho',
    prepTime: '2',
    price: '1.50',
    spicy: false,
    vegan: false,
  },
  {
    id: '14MolhoDeTahine',
    image:
      'https://firebasestorage.googleapis.com/v0/b/o-gosto-de-beirute.appspot.com/o/molhoTahine.jpg?alt=media&token=8761d590-ed0c-4bba-8ec9-ed5d0902da99',
    name: 'Molho de Tahine',
    ingredients: 'Nosso molho de Tahine, baseado do gergelim, Caseiro',
    type: 'Molho',
    prepTime: '2',
    price: '1.50',
    spicy: false,
    vegan: true,
  },
]
