export const bebidas = [
  {
    id: '15CocaLata',
    image:
      'https://firebasestorage.googleapis.com/v0/b/o-gosto-de-beirute.appspot.com/o/imagesGeneral%2FcocaOriginalLata.png?alt=media&token=97c81143-5ce4-41bf-b53e-cacaa6382c07',
    name: 'Coca-Cola Lata',
    ingredients: '350ml',
    type: 'Drink',
    prepTime: '1',
    price: '5.00',
    spicy: false,
    vegan: false,
  },
  {
    id: '16CocaZeroLata',
    image:
      'https://firebasestorage.googleapis.com/v0/b/o-gosto-de-beirute.appspot.com/o/imagesGeneral%2FcocaZeroLata.jpg?alt=media&token=283a74d7-c4e4-49ef-8d91-d375ef5135fc',
    name: 'Coca-Cola Zero Lata',
    ingredients: '350ml',
    type: 'Drink',
    prepTime: '1',
    price: '5.00',
    spicy: false,
    vegan: false,
  },
  {
    id: '17Coca600',
    image:
      'https://firebasestorage.googleapis.com/v0/b/o-gosto-de-beirute.appspot.com/o/imagesGeneral%2Fcoca600ml.png?alt=media&token=857adf50-a5ec-404c-9445-11fee5508dda',
    name: 'Coca-Cola 600ml',
    ingredients: '600ml',
    type: 'Drink',
    prepTime: '1',
    price: '6.00',
    spicy: false,
    vegan: false,
  },
  {
    id: '18Coca1L',
    image:
      'https://firebasestorage.googleapis.com/v0/b/o-gosto-de-beirute.appspot.com/o/imagesGeneral%2Fcoca1Litro.png?alt=media&token=8d6666e0-b0af-40d0-8482-ec6c3adfe88a',
    name: 'Coca-Cola 1L',
    ingredients: '1000ml',
    type: 'Drink',
    prepTime: '1',
    price: '7.50',
    spicy: false,
    vegan: false,
  },
  {
    id: '19SucoKairosLaranja',
    image:
      'https://firebasestorage.googleapis.com/v0/b/o-gosto-de-beirute.appspot.com/o/imagesGeneral%2FkairosSucosLogo.jpg?alt=media&token=a28eb727-9e71-4ff9-8cdc-a62710fcbfd2',
    name: 'Suco Kairos Laranja 300ml',
    ingredients: '300ml',
    type: 'Drink',
    prepTime: '1',
    price: '5.00',
    spicy: false,
    vegan: false,
  },
  {
    id: '20SucoKairosMaracuja',
    image:
      'https://firebasestorage.googleapis.com/v0/b/o-gosto-de-beirute.appspot.com/o/imagesGeneral%2FkairosSucosLogo.jpg?alt=media&token=a28eb727-9e71-4ff9-8cdc-a62710fcbfd2',
    name: 'Suco Kairos Maracuja 300ml',
    ingredients: '300ml',
    type: 'Drink',
    prepTime: '1',
    price: '5.00',
    spicy: false,
    vegan: false,
  },
  {
    id: '21SucoKairosCaju',
    image:
      'https://firebasestorage.googleapis.com/v0/b/o-gosto-de-beirute.appspot.com/o/imagesGeneral%2FkairosSucosLogo.jpg?alt=media&token=a28eb727-9e71-4ff9-8cdc-a62710fcbfd2',
    name: 'Suco Kairos Caju 300ml',
    ingredients: '300ml',
    type: 'Drink',
    prepTime: '1',
    price: '5.00',
    spicy: false,
    vegan: false,
  },
  {
    id: '22SpriteLata',
    image:
      'https://firebasestorage.googleapis.com/v0/b/o-gosto-de-beirute.appspot.com/o/imagesGeneral%2FspriteLata.jpg?alt=media&token=60ce6c42-b206-4f16-a687-e58f1ed5cfb3',
    name: 'Sprite Lata',
    ingredients: '350ml',
    type: 'Drink',
    prepTime: '1',
    price: '5.00',
    spicy: false,
    vegan: false,
  },
  {
    id: '23FantaLata',
    image:
      'https://firebasestorage.googleapis.com/v0/b/o-gosto-de-beirute.appspot.com/o/imagesGeneral%2FfantaLaranjaLata.png?alt=media&token=f1c07b09-0d29-4b4e-a99b-872cfb73e8aa',
    name: 'Fanta Lata',
    ingredients: '350ml',
    type: 'Drink',
    prepTime: '1',
    price: '5.00',
    spicy: false,
    vegan: false,
  },
  {
    id: '24GuaranaLata',
    image:
      'https://firebasestorage.googleapis.com/v0/b/o-gosto-de-beirute.appspot.com/o/imagesGeneral%2FguaranaoriginalLata.jpg?alt=media&token=c76c2446-5fe6-40f7-a661-60fecd514fbd',
    name: 'Guarana Antactica Lata',
    ingredients: '350ml',
    type: 'Drink',
    prepTime: '1',
    price: '5.00',
    spicy: false,
    vegan: false,
  },
  {
    id: '25GuaranaZeroLata',
    image:
      'https://firebasestorage.googleapis.com/v0/b/o-gosto-de-beirute.appspot.com/o/imagesGeneral%2FguaranaSemAcucarLata.jpg?alt=media&token=1b6513c6-5ee2-4d2c-a8ba-fdf591f2f73b',
    name: 'Guarana Antactica Zero Lata',
    ingredients: '350ml',
    type: 'Drink',
    prepTime: '1',
    price: '5.00',
    spicy: false,
    vegan: false,
  },
  {
    id: '26SchweppsCitrusLata',
    image:
      'https://firebasestorage.googleapis.com/v0/b/o-gosto-de-beirute.appspot.com/o/imagesGeneral%2FshcqweppsCitrusLata.jpg?alt=media&token=7048b352-7fef-4940-a590-87eed8bfda89',
    name: 'Schwepps Citrus Lata',
    ingredients: '350ml',
    type: 'Drink',
    prepTime: '1',
    price: '5.00',
    spicy: false,
    vegan: false,
  },
  {
    id: '27SchweppsTonicaLata',
    image:
      'https://firebasestorage.googleapis.com/v0/b/o-gosto-de-beirute.appspot.com/o/imagesGeneral%2FschweppsTonicaLata.jpg?alt=media&token=63154a70-e038-48c9-8b36-cf5598b431da',
    name: 'Schwepps Tonica Lata',
    ingredients: '350ml',
    type: 'Drink',
    prepTime: '1',
    price: '5.00',
    spicy: false,
    vegan: false,
  },
  {
    id: '28AguaCrystalSemGas500',
    image:
      'https://firebasestorage.googleapis.com/v0/b/o-gosto-de-beirute.appspot.com/o/imagesGeneral%2FaguacrystalSemGas500.png?alt=media&token=fd5436f6-92b0-4381-b21b-d4df518d8da3',
    name: 'Agua Crystal Sem Gas 500ml',
    ingredients: '500ml',
    type: 'Drink',
    prepTime: '1',
    price: '2.50',
    spicy: false,
    vegan: false,
  },
  {
    id: '29AguaCrystalComGas500',
    image:
      'https://firebasestorage.googleapis.com/v0/b/o-gosto-de-beirute.appspot.com/o/imagesGeneral%2FaguaCrystalcomGas500.png?alt=media&token=abeb80ca-5b9c-4005-b669-74e44dc8a3b7',
    name: 'Agua Crystal Com Gas 500ml',
    ingredients: '500ml',
    type: 'Drink',
    prepTime: '1',
    price: '3.50',
    spicy: false,
    vegan: false,
  },
]
