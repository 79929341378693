export const menu = [
  {
    id: '1ShawarmaCarne',
    name: 'Shawarma Carne',
    type: 'Sandwich',
    quantity: 1,
    comment: '',
    price: 22,
    priceIfood: 24.8,
  },
  {
    id: '2ShawarmaFrango',
    name: 'Shawarma Frango',
    type: 'Sandwich',
    quantity: 1,
    comment: '',
    price: 18,
    priceIfood: 20,
  },
  {
    id: '3FajitaCarne',
    name: 'Fajita Carne',
    type: 'Sandwich',
    quantity: 1,
    comment: '',
    price: 26,
    priceIfood: 28.6,
  },
  {
    id: '4FajitaFrango',
    name: 'Fajita Frango',
    type: 'Sandwich',
    quantity: 1,
    comment: '',
    price: 22,
    priceIfood: 24.8,
  },
  {
    id: '5CarnePicante',
    name: 'Carne Picante',
    type: 'Sandwich',
    quantity: 1,
    comment: '',
    price: 28,
    priceIfood: 30.6,
  },
  {
    id: '6Falafel',
    name: 'Falafel',
    type: 'Sandwich',
    quantity: 1,
    comment: '',
    price: 20,
    priceIfood: 22,
  },
  {
    id: '7BatataGrande',
    name: 'Batata Grande',
    type: 'Porcao',
    quantity: 1,
    comment: '',
    price: 8,
    priceIfood: 10.1,
  },
  {
    id: '8BatataPequena',
    name: 'Batata Pequena',
    type: 'Porcao',
    quantity: 1,
    comment: '',
    price: 5,
    priceIfood: 6.6,
  },
  {
    id: '9QuibeCarne',
    name: 'Quibe de Carne',
    type: 'Salgado',
    quantity: 1,
    comment: '',
    price: 3.5,
    priceIfood: 4.99,
  },
  {
    id: '10PastelQuiejo',
    name: 'Pastelzinho de Quiejo',
    type: 'Salgado',
    quantity: 1,
    comment: '',
    price: 3.5,
    priceIfood: 4.99,
  },
  // {
  //   id: '11EsfihaCarne',
  //   name: 'Esfiha de Carne',
  //   type: 'Salgado',
  //   quantity: 1,
  //   comment: '',
  //   price: 3.5,
  //   priceIfood: 4.99,
  // },
  {
    id: '12MolhoEspecial',
    name: 'Molho Especial',
    type: 'Molho',
    quantity: 1,
    comment: '',
    price: 1.5,
    priceIfood: 2.2,
  },
  {
    id: '13CremeDeAlho',
    name: 'Creme de Alho',
    type: 'Molho',
    quantity: 1,
    comment: '',
    price: 1.5,
    priceIfood: 2.2,
  },
  {
    id: '14MolhoDeTahine',
    name: 'Molho de Tahine',
    type: 'Molho',
    quantity: 1,
    comment: '',
    price: 1.5,
    priceIfood: 2.2,
  },
  {
    id: '15CocaLata',
    name: 'Coca Lata',
    type: 'Drink',
    quantity: 1,
    comment: '',
    price: 5,
    priceIfood: 6,
  },
  {
    id: '16CocaZeroLata',
    name: 'Coca Zero Lata',
    type: 'Drink',
    quantity: 1,
    comment: '',
    price: 5,
    priceIfood: 6,
  },
  {
    id: '17Coca600',
    name: 'Coca 600ml',
    type: 'Drink',
    quantity: 1,
    comment: '',
    price: 6,
    priceIfood: 7.5,
  },
  {
    id: '30Coca600Zero',
    name: 'Coca 600ml Zero',
    type: 'Drink',
    quantity: 1,
    comment: '',
    price: 6,
    priceIfood: 7.5,
  },
  {
    id: '18Coca1L',
    name: 'Coca 1L',
    type: 'Drink',
    quantity: 1,
    comment: '',
    price: 7.5,
    priceIfood: 9,
  },
  {
    id: '19SucoKairosLaranja',
    name: 'Suco Kairos Laranja',
    type: 'Drink',
    quantity: 1,
    comment: '',
    price: 5,
    priceIfood: 6,
  },
  {
    id: '20SucoKairosMaracuja',
    name: 'Suco Kairos Maracuja',
    type: 'Drink',
    quantity: 1,
    comment: '',
    price: 5,
    priceIfood: 6,
  },
  {
    id: '21SucoKairosCaju',
    name: 'Suco Kairos Caju',
    type: 'Drink',
    quantity: 1,
    comment: '',
    price: 5,
    priceIfood: 6,
  },
  {
    id: '22SpriteLata',
    name: 'Sprite Lata',
    type: 'Drink',
    quantity: 1,
    comment: '',
    price: 5,
    priceIfood: 6,
  },
  {
    id: '23FantaLata',
    name: 'Fanta Lata',
    type: 'Drink',
    quantity: 1,
    comment: '',
    price: 5,
    priceIfood: 6,
  },
  {
    id: '24GuaranaLata',
    name: 'Guarana Lata',
    type: 'Drink',
    quantity: 1,
    comment: '',
    price: 5,
    priceIfood: 6,
  },
  {
    id: '25GuaranaZeroLata',
    name: 'Guarana Zero Lata',
    type: 'Drink',
    quantity: 1,
    comment: '',
    price: 5,
    priceIfood: 6,
  },
  {
    id: '26SchweppsCitrusLata',
    name: 'Schwepps Citrus Lata',
    type: 'Drink',
    quantity: 1,
    comment: '',
    price: 5,
    priceIfood: 6,
  },
  {
    id: '27SchweppsTonicaLata',
    name: 'Schwepps Tonica Lata',
    type: 'Drink',
    quantity: 1,
    comment: '',
    price: 5,
    priceIfood: 6,
  },
  {
    id: '31HeinekenLata',
    name: 'Heineken Lata',
    type: 'Drink',
    quantity: 1,
    comment: '',
    price: 7,
    priceIfood: 8.5,
  },
  {
    id: '28AguaCrystalSemGas500',
    name: 'Agua Sem Gas 500ml',
    type: 'Drink',
    quantity: 1,
    comment: '',
    price: 2.5,
    priceIfood: 3.5,
  },
  {
    id: '29AguaCrystalComGas500',
    name: 'Agua Com Gas 500ml',
    type: 'Drink',
    quantity: 1,
    comment: '',
    price: 3.5,
    priceIfood: 4,
  },
]
// 31 items
